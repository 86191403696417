<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Game status</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="$store.state.whichToShow == 'keno'">
          <div
            v-if="!pageLoading"
            class="text-center white pa-16 pl-2 pr-2 pt-2"
            style="border-radius: 3px"
          >
            <v-layout
              id="gameStat"
              row
              wrap
              justify-space-around
              style="font-size: 18px"
            >
              <v-flex xs12 class="text-center ma-5">
                <v-btn
                  small
                  text
                  dark
                  class="pink mr-2"
                  @click="
                    stertGame(
                      $store.state.adminURL.split('graphql')[0] + 'getApp'
                    )
                  "
                >
                  <span class="text-capitalize">Start Keno</span>
                </v-btn>
                <span class="red--text" style="font-size: 14px"
                  >Make sure if last game time is less by 6 min and 10 sec, then
                  click only once!</span
                >
              </v-flex>
              <v-flex xs12 md6>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <v-layout
                      row
                      wrap
                      class="pt-3 pl-5 pr-5 text-left"
                      style="font-size: 14px"
                      justify-space-around
                    >
                      <v-flex xs6 md4 class="pa-1">
                        <p
                          class="orange--text"
                          style="font-size: 18px"
                          v-if="currentGame.length > 0"
                        >
                          Keno Game# {{ currentGame[0].game_number }}
                        </p>
                        <p class="orange--text" style="font-size: 18px" v-else>
                          No game
                        </p>
                      </v-flex>
                      <v-flex xs6 md4 class="pr-1 pt-2">
                        <p>
                          <v-progress-linear
                            v-model="timePercent"
                            height="8"
                          ></v-progress-linear>
                        </p>
                      </v-flex>
                      <v-flex
                        class="red--text pa-1"
                        xs12
                        md4
                        v-if="minutes == '00' && parseInt(seconds) <= 20"
                      >
                        <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                      </v-flex>

                      <v-flex class="pa-1" xs12 md4 v-else>
                        <p>GAME CLOSES IN {{ minutes }}:{{ seconds }}</p>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md3>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <h4>Current Money</h4>
                    <span class="green--text">
                      {{ totalMoneyCurrentBets }}
                      <span style="font-size: 11px">ETB</span>
                    </span>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md3>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <h4>Current Tickets</h4>
                    <span class="blue--text" v-if="currentGame.length > 0">
                      {{ currentGame[0].getTickets.length }}
                    </span>
                    <span class="pink--text" v-else>0 Number of tickets</span>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12 md3>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <h4>Current Bet</h4>
                    <span class="blue--text" v-if="totalBetsCurrentGame > 0">
                      {{ totalBetsCurrentGame }}
                    </span>
                    <span class="pink--text" v-else>0 Number of Bets</span>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </div>
        <div v-if="$store.state.whichToShow == 'spin'">
          <div
            v-if="!pageLoading"
            class="text-center white pa-16 pl-2 pr-2 pt-2"
            style="border-radius: 3px"
          >
            <v-layout
              id="gameStat"
              row
              wrap
              justify-space-around
              style="font-size: 18px"
            >
              <v-flex xs12 class="text-center ma-5">
                <v-btn
                  small
                  text
                  dark
                  class="pink mr-2"
                  @click="
                    stertGame(
                      $store.state.spinAdminURL.split('graphql')[0] + 'getApp'
                    )
                  "
                >
                  <span class="text-capitalize">Start Spin</span>
                </v-btn>
                <span class="red--text" style="font-size: 14px"
                  >Make sure if last game time is less by 5 min and 22 sec, then
                  click only once!</span
                >
              </v-flex>
              <v-flex xs12 md6>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <v-layout
                      row
                      wrap
                      class="pt-3 pl-5 pr-5 text-left"
                      style="font-size: 14px"
                      justify-space-around
                    >
                      <v-flex xs6 md4 class="pa-1">
                        <p
                          class="orange--text"
                          style="font-size: 18px"
                          v-if="spinCurrentGame.length > 0"
                        >
                          Spin Game# {{ spinCurrentGame[0].game_number }}
                        </p>
                        <p class="orange--text" style="font-size: 18px" v-else>
                          No game
                        </p>
                      </v-flex>
                      <v-flex xs6 md4 class="pr-1 pt-2">
                        <p>
                          <v-progress-linear
                            v-model="spinTimePercent"
                            height="8"
                          ></v-progress-linear>
                        </p>
                      </v-flex>
                      <v-flex
                        class="red--text pa-1"
                        xs12
                        md4
                        v-if="
                          spinMinutes == '00' && parseInt(spinSeconds) <= 20
                        "
                      >
                        <p>
                          GAME CLOSES IN {{ spinMinutes }}:{{ spinSeconds }}
                        </p>
                      </v-flex>

                      <v-flex class="pa-1" xs12 md4 v-else>
                        <p>
                          GAME CLOSES IN {{ spinMinutes }}:{{ spinSeconds }}
                        </p>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md3>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <h4>Current Money</h4>
                    <span class="green--text">
                      {{ spinTotalMoneyCurrentBets }}
                      <span style="font-size: 11px">ETB</span>
                    </span>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md3>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <h4>Current Tickets</h4>
                    <span class="blue--text" v-if="spinCurrentGame.length > 0">
                      {{ spinCurrentGame[0].getTickets.length }}
                    </span>
                    <span class="pink--text" v-else>0 Number of tickets</span>
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12 md3>
                <v-card class="ma-2 pb-2" height="120px">
                  <v-card-text>
                    <h4>Current Bet</h4>
                    <span
                      class="blue--text"
                      v-if="spinTotalBetsCurrentGame > 0"
                    >
                      {{ spinTotalBetsCurrentGame }}
                    </span>
                    <span class="pink--text" v-else>0 Number of Bets</span>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      currentGame: [],
      timePercent: 0,
      timeInSeconds: 0,
      minutes: 3,
      seconds: 56,
      totalMoneyCurrentBets: 0,
      totalBetsCurrentGame: 0,

      spinCurrentGame: [],
      spinTimePercent: 0,
      spinTimeInSeconds: 0,
      spinMinutes: 4,
      spinSeconds: 30,
      spinTotalMoneyCurrentBets: 0,
      spinTotalBetsCurrentGame: 0,
    };
  },

  methods: {
    async stertGame(url) {
      await axios.get(url);
    },
    async getPendingGame() {
      this.pageLoading = true;
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                getPendingGame{
                   game_id                   
                   game_number                   
                   game_number
                   drawn_numbers
                   game_date
                   getTickets{
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award

                   }
                 }
              }
              `,
          },
        });
        this.currentGame = currentGameResult.data.data.getPendingGame;
        this.totalMoneyCurrentBets = 0;
        this.totalBetsCurrentGame = 0;
        if (this.currentGame.length > 0) {
          // alert(Date.now());
          for (let t = 0; t < this.currentGame[0].getTickets.length; t++) {
            var tickets =
              this.currentGame[0].getTickets[t].choosen_numbers.split(":");
            var others = this.currentGame[0].getTickets[t].others.split(":");
            if (tickets[0] != "") {
              this.totalBetsCurrentGame =
                this.totalBetsCurrentGame + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.totalMoneyCurrentBets =
                  this.totalMoneyCurrentBets +
                  parseFloat(tickets[j].split("*")[1]);
              }
            }

            if (others[0] != "") {
              this.totalBetsCurrentGame =
                this.totalBetsCurrentGame + others.length;
              for (let k = 0; k < others.length; k++) {
                this.totalMoneyCurrentBets =
                  this.totalMoneyCurrentBets +
                  parseFloat(others[k].split("*")[1]);
              }
            }
          }

          if (this.currentGame.length > 0) {
            this.timeInSeconds = Math.floor(
              236 - (Date.now() - Number(this.currentGame[0].game_date)) / 1000
            );

            this.timePercent =
              0.4238 *
              Math.floor(
                (Date.now() - Number(this.currentGame[0].game_date)) / 1000
              );
          } else {
            this.timePercent = 0;
            this.minutes = "00";
            this.seconds = "00";
          }
        }
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
    async spinGetPendingGame() {
      this.pageLoading = true;
      try {
        var currentGameResult = await axios({
          method: "POST",
          url: this.$store.state.spinAdminURL,

          data: {
            query: `{
                getPendingGame{
                   game_id                   
                   game_number                   
                   game_number
                   drawn_numbers
                   game_date
                   getTickets{
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award

                   }
                 }
              }
              `,
          },
        });
        this.spinCurrentGame = currentGameResult.data.data.getPendingGame;
        this.spinTotalMoneyCurrentBets = 0;
        this.spinTotalBetsCurrentGame = 0;
        if (this.spinCurrentGame.length > 0) {
          // alert(Date.now());
          for (let t = 0; t < this.spinCurrentGame[0].getTickets.length; t++) {
            var tickets =
              this.spinCurrentGame[0].getTickets[t].choosen_numbers.split(":");

            if (tickets[0] != "") {
              this.spinTotalBetsCurrentGame =
                this.spinTotalBetsCurrentGame + tickets.length;
              for (let j = 0; j < tickets.length; j++) {
                this.spinTotalMoneyCurrentBets =
                  this.spinTotalMoneyCurrentBets +
                  parseFloat(tickets[j].split("*")[1]);
              }
            }
          }

          if (this.spinCurrentGame.length > 0) {
            this.spinTimeInSeconds = Math.floor(
              270 -
                (Date.now() - Number(this.spinCurrentGame[0].game_date)) / 1000
            );

            this.spinTimePercent =
              0.3704 *
              Math.floor(
                (Date.now() - Number(this.spinCurrentGame[0].game_date)) / 1000
              );
          } else {
            this.spinTimePercent = 0;
            this.spinMinutes = "00";
            this.spinSeconds = "00";
          }
        }
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getPendingGame();

      await this.spinGetPendingGame();

      setInterval(async () => {
        if (this.timeInSeconds > 0) {
          this.timeInSeconds--;
          let hours = Math.floor(this.timeInSeconds / 3600);
          this.minutes = Math.floor((this.timeInSeconds - hours * 3600) / 60); // get minutes
          this.seconds = this.timeInSeconds - hours * 3600 - this.minutes * 60;

          if (this.minutes < 10) {
            this.minutes = "0" + this.minutes;
          }
          if (this.seconds < 10) {
            this.seconds = "0" + this.seconds;
          }

          this.timePercent = this.timePercent + 0.4238;
        } else {
          this.timePercent = 0;
          this.minutes = "00";
          this.seconds = "00";
        }
      }, 1000);
      setInterval(async () => {
        if (this.spinTimeInSeconds > 0) {
          this.spinTimeInSeconds--;
          let hours = Math.floor(this.spinTimeInSeconds / 3600);
          this.spinMinutes = Math.floor(
            (this.spinTimeInSeconds - hours * 3600) / 60
          ); // get minutes
          this.spinSeconds =
            this.spinTimeInSeconds - hours * 3600 - this.spinMinutes * 60;

          if (this.spinMinutes < 10) {
            this.spinMinutes = "0" + this.spinMinutes;
          }
          if (this.spinSeconds < 10) {
            this.spinSeconds = "0" + this.spinSeconds;
          }

          this.spinTimePercent = this.spinTimePercent + 0.3704;
        } else {
          this.spinTimePercent = 0;
          this.spinMinutes = "00";
          this.spinSeconds = "00";
        }
      }, 1000);
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#gameStat {
  max-width: 1200px;
  margin: auto;
}
</style>
