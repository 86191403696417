<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Clients</p>
      <div v-if="$store.state.admin != ''" class="">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white text-center pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <!-- <v-flex xs12 class="mb-5">
            <v-menu rounded="lg">
              <template v-slot:activator="{ attrs, on }">
                <span class="mr-3 pt-3">Ticket Layout: </span>
                <v-btn
                  text
                  class="pl-10 pr-10 text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                  outlined=""
                >
                  {{ ticket_layout }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group
                  v-model="group"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item
                    v-for="item in layouts"
                    :key="item"
                    @click="
                      if (ticket_layout != item) {
                        ticket_layout = item;
                        changeTicketLayout();
                      }
                    "
                  >
                    <v-list-item-title :v-text="item"></v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-flex>
          <v-flex xs12 class="mb-5">
            <v-menu rounded="lg">
              <template v-slot:activator="{ attrs, on }">
                <span class="mr-3 pt-3">Change Logo: </span>
                <v-btn
                  text
                  class="pl-12 pr-12 text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                  outlined=""
                >
                  {{ logo }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item-group
                  v-model="grouplogo"
                  active-class="deep-purple--text text--accent-4"
                >
                  <v-list-item
                    v-for="item in logos"
                    :key="item"
                    @click="
                      if (logo != item) {
                        logo = item;
                        changeLogo();
                      }
                    "
                  >
                    <v-list-item-title :v-text="item"></v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-flex> -->

          <!-- <v-btn
            small
            text
            class="primary mr-2"
            @click="
              cashierRegOrUpdateDialog = true;
              isRegistration = 'Cashier Registration';
            "
          >
            <span class="text-capitalize">Add cashier</span>
          </v-btn> -->

          <v-btn
            small
            text
            class="primary mr-2"
            @click="whichActorToShow = 'partners'"
          >
            <span class="text-capitalize">Partners</span>
          </v-btn>
          <!-- <v-btn
            small
            text
            class="purple darken-3 mr-2"
            @click="editPartnerDialog = true"
            
            dark
          >
            <span class="text-capitalize">Edit Partner</span>
          </v-btn> -->

          <v-btn
            small
            text
            class="purple darken-3 mr-2"
            @click="whichActorToShow = 'branchs'"
            dark
          >
            <span class="text-capitalize">Branchs</span>
          </v-btn>

          <v-btn
            small
            text
            dark
            class="orange darken-3 mr-2"
            @click="whichActorToShow = 'cashiers'"
          >
            <span class="text-capitalize">Cashiers</span>
          </v-btn>

          <!-- <v-btn
            small
            text
            dark
            class="orange darken-3 mr-2"
            @click="blockOrActivatePartner"
            :loading="partnerActivateLoading"
            v-if="partner[0].is_active"
          >
            <span class="text-capitalize">Block Partner</span>
          </v-btn> -->

          <!-- <v-btn
            small
            text
            class="green darken-3 mr-2"
            @click="blockOrActivatePartner"
            v-else
            dark
            :loading="partnerActivateLoading"
          >
            <span class="text-capitalize">Activate Partner</span>
          </v-btn> -->
          <v-flex xs12 style="font-size: 18px" class="text-left">
            <span class="text-capitalize red--text mt-4 ml-10">
              {{ whichActorToShow }}
            </span>
            &nbsp; &nbsp; &nbsp; &nbsp;<a
              @click="
                editPartnerDialog = true;
                isRegistration = 'Partner Registration';
              "
              v-if="whichActorToShow == 'partners'"
              ><v-icon class="blue--text">add</v-icon></a
            >
            <a
              @click="
                cashierRegOrUpdateDialog = true;
                isRegistration = 'Cashier Registration';
              "
              v-if="whichActorToShow == 'cashiers'"
              ><v-icon class="blue--text">add</v-icon></a
            >
            <a
              @click="
                branchRegOrUpdateDialog = true;
                isRegistration = 'Branch Registration';
              "
              v-if="whichActorToShow == 'branchs'"
              ><v-icon class="blue--text">add</v-icon></a
            >
          </v-flex>
          <v-layout
            row
            wrap
            class="mb-15 pa-3"
            style="font-size: 14px"
            v-if="whichActorToShow == 'cashiers'"
          >
            <horizontal-scroll
              class="horizontal-scroll white pa-3 pl-0 pt-0"
              id="report"
            >
              <table style="width: 100%" class="ma-3 mt-0">
                <tr>
                  <th>User Name</th>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Partner, Branch</th>

                  <th>Action</th>
                  <th></th>
                </tr>
                <tr v-for="(cashier, i) in cashiers" :key="i">
                  <td>
                    {{ cashier.user_name }}
                    <!-- <v-btn
                      small
                      text
                      @click="deleteCashier(cashier)"
                      class="mt-1"
                    >
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn> -->
                  </td>
                  <td>
                    {{ cashier.cashier_full_name }}
                  </td>
                  <td>
                    {{ cashier.cashier_phone }}
                  </td>
                  <td>
                    {{ cashier.getPartner[0].partner_full_name }},
                    {{ cashier.getBranch[0].branch_name }}
                  </td>

                  <td>
                    <v-btn
                      small
                      text
                      @click="editClicked(cashier)"
                      class="mt-1"
                    >
                      <v-icon class="blue--text">edit</v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      class="pink pl-4 pr-4 mt-1"
                      x-small
                      text
                      v-if="cashier.is_active"
                      :loading="activateLoading"
                      @click="blockOrActivateCashier(cashier)"
                    >
                      <span class="text-capitalize">Block</span>
                    </v-btn>
                    <v-btn
                      dark
                      class="green mt-1"
                      x-small
                      text
                      v-if="!cashier.is_active"
                      :loading="activateLoading"
                      @click="blockOrActivateCashier(cashier)"
                    >
                      <span class="text-capitalize">unBlock</span>
                    </v-btn>

                    <v-btn
                      small
                      text
                      @click="deleteCashier(cashier.cashier_id)"
                      class="mt-1"
                    >
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn>
                    <v-btn
                      x-small
                      v-if="cashier.is_loggedin == 1"
                      text
                      @click="logout(cashier)"
                      class="mt-1"
                    >
                      <span class="red--text text-capitalize">Logout</span>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      small
                      text
                      @click="clearCoordinate(cashier)"
                      class="mt-1"
                    >
                      <span class="text-capitalize red--text">Clear</span>
                    </v-btn>

                    <v-btn small text class="mt-1 text-capitalize">
                      <v-switch
                        @click="changeAllowLoggin(cashier)"
                        class=""
                        style="float: right; clear: right"
                        v-model="cashier.is_allowed_anywhere"
                        label="Allow login"
                      ></v-switch>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>
          <v-layout
            row
            wrap
            class="mb-15 pa-3"
            style="font-size: 14px"
            v-if="whichActorToShow == 'partners'"
          >
            <horizontal-scroll
              class="horizontal-scroll white pa-3 pl-0 pt-0"
              id="report"
            >
              <table style="width: 100%" class="ma-3 mt-0">
                <tr>
                  <th>User Name</th>
                  <th>Full Name</th>
                  <th>Phone Number</th>
                  <th>Shop Number</th>

                  <th>Action</th>
                </tr>
                <tr v-for="(partners, i) in partner" :key="i">
                  <td>
                    {{ partners.user_name }}
                    <!-- <v-btn
                    small
                    text
                    @click="deleteCashier(cashier)"
                    class="mt-1"
                  >
                    <v-icon class="red--text">delete</v-icon>
                  </v-btn> -->
                  </td>
                  <td>
                    {{ partners.partner_full_name }}
                  </td>
                  <td>
                    {{ partners.partner_phone }}
                  </td>
                  <td>
                    {{ partners.shop_number }}
                  </td>

                  <td>
                    <v-btn
                      small
                      text
                      @click="editParerClicked(partners)"
                      class="mt-1"
                    >
                      <v-icon class="blue--text">edit</v-icon>
                    </v-btn>

                    <v-btn
                      x-small
                      text
                      dark
                      class="orange darken-3 mr-2"
                      @click="blockOrActivatePartner(partners)"
                      :loading="partnerActivateLoading"
                      v-if="partners.is_active"
                    >
                      <span class="text-capitalize">Block Partner</span>
                    </v-btn>

                    <v-btn
                      x-small
                      text
                      class="green darken-3 mr-2"
                      @click="blockOrActivatePartner(partners)"
                      v-else
                      dark
                      :loading="partnerActivateLoading"
                    >
                      <span class="text-capitalize">Activate Partner</span>
                    </v-btn>

                    <v-btn
                      small
                      text
                      @click="deletePartner(partners.partner_id)"
                      class="mt-1"
                    >
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn>

                    <span v-if="partner.length > 0">
                      <a
                        @click="
                          {
                            depositDialog = true;
                            selectedDeposit = partner;
                            calcCredit();
                          }
                        "
                        class="ml-1"
                        v-if="partners.getDeposit.length > 0"
                      >
                        <v-icon class="pink--text">question_mark</v-icon>
                      </a>
                    </span>
                  </td>
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>

          <v-layout
            row
            wrap
            class="mb-15 pa-3"
            style="font-size: 14px"
            v-if="whichActorToShow == 'branchs'"
          >
            <horizontal-scroll
              class="horizontal-scroll white pa-3 pl-0 pt-0"
              id="report"
            >
              <table style="width: 100%" class="ma-3 mt-0">
                <tr>
                  <th>Branch Name</th>
                  <th>Partner Name</th>

                  <th>Action</th>
                </tr>
                <tr v-for="branch in branchs" :key="branch.branch_id">
                  <td>
                    {{ branch.branch_name }}
                    <!-- <v-btn
                  small
                  text
                  @click="deleteCashier(cashier)"
                  class="mt-1"
                >
                  <v-icon class="red--text">delete</v-icon>
                </v-btn> -->
                  </td>
                  <td>
                    {{ branch.getPartner[0].partner_full_name }}
                  </td>

                  <td>
                    <v-btn
                      small
                      text
                      @click="editBranchClicked(branch)"
                      class="mt-1"
                    >
                      <v-icon class="blue--text">edit</v-icon>
                    </v-btn>

                    <!-- <v-btn
                      x-small
                      text
                      dark
                      class="orange darken-3 mr-2"
                      @click="blockOrActivatePartner(branch)"
                      :loading="partnerActivateLoading"
                      v-if="branch.is_active"
                    >
                      <span class="text-capitalize">Block Branch</span>
                    </v-btn>

                    <v-btn
                      x-small
                      text
                      class="green darken-3 mr-2"
                      @click="blockOrActivatePartner(branch)"
                      v-else
                      dark
                      :loading="partnerActivateLoading"
                    >
                      <span class="text-capitalize">Activate Branch</span>
                    </v-btn> -->

                    <v-btn
                      small
                      text
                      @click="deleteBranch(branch.branch_id)"
                      class="mt-1"
                    >
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>
        </div>

        <v-dialog v-model="cashierRegOrUpdateDialog" persistent max-width="420">
          <v-card class="text-center pl-5 pr-5 pb-10">
            <v-card-title style="font-size: 13px">
              {{ isRegistration }} <v-spacer></v-spacer>
              <v-btn text small @click="cashierRegOrUpdateDialog = false">
                <span class="text-capitalize red--text">close</span>
              </v-btn></v-card-title
            >

            <v-form @submit.prevent="" ref="form">
              <v-card-text>
                <P>Fill the following required fields</P>
                <v-flex
                  xs12
                  class="mb-5"
                  v-if="isRegistration == 'Cashier Registration'"
                >
                  <v-menu rounded="lg">
                    <template v-slot:activator="{ attrs, on }">
                      <span class="mr-3 pt-3">Choose Partner: </span>
                      <v-btn
                        text
                        class="pl-10 pr-10 text-capitalize"
                        v-bind="attrs"
                        v-on="on"
                        outlined=""
                      >
                        {{ choosedPartner }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="group"
                        active-class="deep-purple--text text--accent-4"
                      >
                        <v-list-item
                          v-for="item in partner"
                          :key="item.partner_id"
                          @click="
                            choosedPartner =
                              item.partner_full_name + ',' + item.partner_id
                          "
                        >
                          <v-list-item-title>{{
                            item.partner_full_name + "," + item.partner_id
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  class="mb-5"
                  v-if="isRegistration == 'Cashier Registration'"
                >
                  <v-menu rounded="lg">
                    <template v-slot:activator="{ attrs, on }">
                      <span class="mr-3 pt-3">Choose Branch: </span>
                      <v-btn
                        text
                        class="pl-10 pr-10 text-capitalize"
                        v-bind="attrs"
                        v-on="on"
                        outlined=""
                      >
                        {{ choosedBranch }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="group"
                        active-class="deep-purple--text text--accent-4"
                      >
                        <v-list-item
                          v-for="item in branchs"
                          :key="item.branchs_id"
                          @click="
                            choosedBranch =
                              item.branch_name + ',' + item.branch_id
                          "
                        >
                          <v-list-item-title>{{
                            item.branch_name + "," + item.branch_id
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-text-field
                  style="font-size: 11px"
                  v-model="fullName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Full Name"
                  required
                  dense
                  outlined
                  prepend-icon="person"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="phoneNumber"
                  :rules="[(v) => !!v || 'Required']"
                  label="Phone Number"
                  required
                  dense
                  outlined
                  prepend-icon="phone"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="address"
                  :rules="[(v) => !!v || 'Required']"
                  label="Cashier Address"
                  required
                  dense
                  outlined
                  prepend-icon="location_on"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="userName"
                  :rules="[(v) => !!v || 'Required']"
                  label="User Name"
                  required
                  dense
                  outlined
                  prepend-icon="group"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="password"
                  :rules="[(v) => !!v || 'Required']"
                  label="Password"
                  required
                  dense
                  outlined
                  prepend-icon="lock"
                ></v-text-field>

                <v-btn
                  v-if="isRegistration == 'Cashier Registration'"
                  small
                  text
                  class="primary"
                  @click="registerCashier"
                  :loading="regOrUpdateLoading"
                  type="submit"
                >
                  <span class="text-capitalize">Register</span>
                </v-btn>

                <v-btn
                  v-else
                  small
                  text
                  class="primary"
                  :loading="regOrUpdateLoading"
                  type="submit"
                  @click="updateCashier"
                >
                  <span class="text-capitalize">Update</span>
                </v-btn>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog v-model="editPartnerDialog" persistent max-width="420">
          <v-card class="text-center pl-5 pr-5 pb-10">
            <v-card-title style="font-size: 13px">
              {{ isRegistration }} <v-spacer></v-spacer>
              <v-btn text small @click="editPartnerDialog = false">
                <span class="text-capitalize red--text">close</span>
              </v-btn></v-card-title
            >

            <v-form @submit.prevent="" ref="partnerForm">
              <v-card-text>
                <v-layout
                  row
                  wrap
                  justify-space-around
                  v-if="isRegistration == 'Update Partner'"
                >
                  <v-flex xs12 class="mb-5">
                    <v-menu rounded="lg">
                      <template v-slot:activator="{ attrs, on }">
                        <span class="mr-3 pt-3">Ticket Layout: </span>
                        <v-btn
                          text
                          class="pl-10 pr-10 text-capitalize"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ ticket_layout }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="group"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            v-for="item in layouts"
                            :key="item"
                            @click="
                              if (ticket_layout != item) {
                                ticket_layout = item;
                                changeTicketLayout();
                              }
                            "
                          >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 class="mb-5">
                    <v-menu rounded="lg">
                      <template v-slot:activator="{ attrs, on }">
                        <span class="mr-3 pt-3">Change Logo: </span>
                        <v-btn
                          text
                          class="pl-12 pr-12 text-capitalize"
                          v-bind="attrs"
                          v-on="on"
                          outlined=""
                        >
                          {{ logo }}
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item-group
                          v-model="grouplogo"
                          active-class="deep-purple--text text--accent-4"
                        >
                          <v-list-item
                            v-for="item in logos"
                            :key="item"
                            @click="
                              if (logo != item) {
                                logo = item;
                                changeLogo();
                              }
                            "
                          >
                            <v-list-item-title>{{ item }}</v-list-item-title>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-menu>
                  </v-flex>

                  <v-flex xs9>
                    <v-text-field
                      style="font-size: 11px"
                      v-model="day_block"
                      :rules="[(v) => !!v || 'Required']"
                      label="Days to block"
                      required
                      dense
                      outlined
                      :hide-details="true"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs2 v-if="!isNaN(day_block) && day_block != ''">
                    <v-btn
                      small
                      text
                      class="primary"
                      :loading="updateBlockingDateLoading"
                      @click="updateBlockingDate"
                    >
                      <span class="text-capitalize">Save</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs12>
                    <v-switch
                      @click="changeAllow"
                      :loading="allowCashierChangeLoading"
                      class="mr-5"
                      v-model="is_allowed_to_change"
                      label="Allow  partner to modify cashier"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12 class="text-left">
                    <v-switch
                      @click="changeMonitorAllow"
                      :loading="allowMonitorLoading"
                      class="mr-5"
                      v-model="allow_monitor_anywhere"
                      label="Allow monitor any where"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12>
                    <v-btn
                      @click="clearMonitorCoordinate"
                      text
                      small
                      class="pink--text pl-3 pr-3 text-capitalize"
                    >
                      Clear monitor coordinates
                    </v-btn>
                  </v-flex>
                </v-layout>

                <br />

                <v-text-field
                  style="font-size: 11px"
                  v-model="partnerFullName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Full Name"
                  required
                  dense
                  outlined
                  prepend-icon="person"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="partnerPhoneNumber"
                  :rules="[(v) => !!v || 'Required']"
                  label="Phone Number"
                  required
                  dense
                  outlined
                  prepend-icon="phone"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="shopNumber"
                  :rules="[(v) => !!v || 'Required']"
                  label="Shop Number"
                  required
                  dense
                  outlined
                  prepend-icon="money"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="partnerUserName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Partner User Name"
                  required
                  dense
                  outlined
                  prepend-icon="group"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="partnerPassword"
                  :rules="[(v) => !!v || 'Required']"
                  label="Partner Password"
                  required
                  dense
                  outlined
                  prepend-icon="lock"
                ></v-text-field>

                <v-text-field
                  style="font-size: 11px"
                  v-model="supper_partner_username"
                  :rules="[(v) => !!v || 'Required']"
                  label="Super Partner Username"
                  required
                  dense
                  outlined
                  prepend-icon="person"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="supper_partner_password"
                  :rules="[(v) => !!v || 'Required']"
                  label="Super Partner Password"
                  required
                  dense
                  outlined
                  prepend-icon="lock"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="monitor_username"
                  :rules="[(v) => !!v || 'Required']"
                  label="Monitor Username"
                  required
                  dense
                  outlined
                  prepend-icon="laptop"
                ></v-text-field>
                <v-text-field
                  style="font-size: 11px"
                  v-model="monitor_password"
                  :rules="[(v) => !!v || 'Required']"
                  label="Monitor Password"
                  required
                  dense
                  outlined
                  prepend-icon="lock"
                ></v-text-field>

                <v-btn
                  v-if="isRegistration == 'Update Partner'"
                  small
                  text
                  class="primary"
                  type="submit"
                  :loading="updatePartnerLoading"
                  @click="updatePartner"
                >
                  <span class="text-capitalize">Save Changes </span>
                </v-btn>
                <v-btn
                  v-else
                  small
                  text
                  class="primary"
                  type="submit"
                  :loading="updatePartnerLoading"
                  @click="addPartnerData"
                >
                  <span class="text-capitalize">Register </span>
                </v-btn>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog v-model="branchRegOrUpdateDialog" persistent max-width="420">
          <v-card class="text-center pl-5 pr-5 pb-10">
            <v-card-title style="font-size: 13px">
              {{ isRegistration }} <v-spacer></v-spacer>
              <v-btn text small @click="branchRegOrUpdateDialog = false">
                <span class="text-capitalize red--text">close</span>
              </v-btn></v-card-title
            >

            <v-form @submit.prevent="" ref="branchForm">
              <v-card-text>
                <br />
                <v-flex
                  xs12
                  class="mb-5"
                  v-if="isRegistration != 'Update Branch'"
                >
                  <v-menu rounded="lg">
                    <template v-slot:activator="{ attrs, on }">
                      <span class="mr-3 pt-3">Choose Partner: </span>
                      <v-btn
                        text
                        class="pl-10 pr-10 text-capitalize"
                        v-bind="attrs"
                        v-on="on"
                        outlined=""
                      >
                        {{ choosedPartner }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="group"
                        active-class="deep-purple--text text--accent-4"
                      >
                        <v-list-item
                          v-for="item in partner"
                          :key="item.partner_id"
                          @click="
                            choosedPartner =
                              item.partner_full_name + ',' + item.partner_id
                          "
                        >
                          <v-list-item-title>{{
                            item.partner_full_name + "," + item.partner_id
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-flex>

                <v-text-field
                  style="font-size: 11px"
                  v-model="branchFullName"
                  :rules="[(v) => !!v || 'Required']"
                  label="Branch Name"
                  required
                  dense
                  outlined
                  prepend-icon="book"
                ></v-text-field>

                <v-btn
                  v-if="isRegistration == 'Update Branch'"
                  small
                  text
                  class="primary"
                  type="submit"
                  :loading="updatePartnerLoading"
                  @click="updateBranch"
                >
                  <span class="text-capitalize">Save Changes </span>
                </v-btn>
                <v-btn
                  v-else
                  small
                  text
                  class="primary"
                  type="submit"
                  :loading="updatePartnerLoading"
                  @click="addBranchData"
                >
                  <span class="text-capitalize">Register </span>
                </v-btn>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="depositDialog"
          persistent
          max-width="420"
          v-if="selectedDeposit != ''"
        >
          <v-card class="pa-1">
            <v-card-title>Deposit Available </v-card-title>
            <v-card-text>
              <v-layout
                row
                wrap
                class="pt-3"
                v-for="(deposit, i) in selectedDeposit.getDeposit"
                :key="i"
              >
                <v-flex v-if="i != 0" xs12 class="pa-1">
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs6>
                  <span>
                    <strong>Name:</strong>
                    {{ selectedDeposit.partner_full_name }}</span
                  >
                  <br />
                  <span>
                    <strong>Date:</strong>
                    {{
                      new Date(Number(deposit.deposit_date)) | formatDate
                    }}</span
                  >
                </v-flex>

                <v-flex xs4>
                  <span> <strong> Amount: </strong>{{ deposit.amount }}</span
                  ><br />
                  <span>
                    <strong>Transaction ID:</strong>
                    {{ deposit.transaction_id }}</span
                  >
                </v-flex>

                <v-flex xs2 class="pt-2">
                  <a @click="confirmDeposit(deposit)" class="mb-1">
                    <span class="text-capitalize blue--text">Confirm</span>
                  </a>
                  <br />
                  <a @click="rejectDeposit(deposit)" class="">
                    <span class="text-capitalize red--text">Reject</span>
                  </a>
                </v-flex>
              </v-layout>
            </v-card-text>

            <v-form @submit.prevent="" class="pl-4 pr-4">
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="depositDialog = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      regOrUpdateLoading: false,
      activateLoading: false,
      partnerActivateLoading: false,
      cashiers: [],
      partner: [],
      depositDialog: false,
      selectedDeposit: "",
      partnerCreditBalance: 0,
      cashierRegOrUpdateDialog: false,
      cashierId: "",
      isRegistration: "",
      fullName: "",
      phoneNumber: "",
      address: "",
      userName: "",
      password: "",
      editPartnerDialog: false,
      whichActorToShow: "partners",
      branchs: [],

      partnerFullName: "",
      partnerPhoneNumber: "",
      shopNumber: "",
      partnerUserName: "",
      partnerPassword: "",
      updatePartnerLoading: false,

      supper_partner_username: "",
      supper_partner_password: "",
      monitor_username: "",
      monitor_password: "",
      day_block_date: "",
      day_block: "",
      branchRegOrUpdateDialog: false,
      branchFullName: "",
      choosedPartner: "",
      choosedBranch: "",

      updateBlockingDateLoading: false,
      is_allowed_to_change: "",
      allow_monitor_anywhere: "",
      allowCashierChangeLoading: false,
      allowMonitorLoading: false,
      ticket_layout: "",
      logo: "",
      layouts: ["Layout 1", "Layout 2"],
      logos: ["Normal"],
      group: null,
      grouplogo: null,
      editPartnerData: "",
      editBranchData: "",
    };
  },

  methods: {
    calcCredit() {
      this.partnerCreditBalance = 0;
      for (let i = 0; i < this.selectedDeposit.getCreditBalance.length; i++) {
        this.partnerCreditBalance =
          this.partnerCreditBalance +
          this.selectedDeposit.getCreditBalance[i].credit_balance;
      }
    },
    editClicked(cashier) {
      this.isRegistration = "Update Cashier";
      this.cashierRegOrUpdateDialog = true;
      this.fullName = cashier.cashier_full_name;
      this.phoneNumber = cashier.cashier_phone;
      this.address = cashier.cashier_address;
      this.userName = cashier.user_name;
      this.password = cashier.password;
      this.cashierId = cashier.cashier_id;
    },

    editParerClicked(partner) {
      this.editPartnerData = partner;
      this.isRegistration = "Update Partner";
      this.partnerFullName = partner.partner_full_name;
      this.partnerPhoneNumber = partner.partner_phone;
      this.shopNumber = partner.shop_number;
      this.partnerUserName = partner.user_name;
      this.partnerPassword = partner.password;

      this.supper_partner_username = partner.supper_partner_username;
      this.supper_partner_password = partner.supper_partner_password;
      this.monitor_username = partner.monitor_username;
      this.monitor_password = partner.monitor_password;
      this.is_allowed_to_change = partner.is_allowed_to_change;
      this.allow_monitor_anywhere = partner.allow_monitor_anywhere;

      this.ticket_layout = partner.ticket_layout;
      this.logo = partner.logo;

      var sec = 24 * 60 * 60 * 1000;

      this.day_block =
        "Given days = " +
        partner.day_block +
        "  ;  Remain days = " +
        Math.round(
          partner.day_block -
            (Date.now() -
              Number(
                partner.day_block_date != "" ? partner.day_block_date : 0
              )) /
              sec
        );

      this.day_block_date = partner.day_block_date;
      this.editPartnerDialog = true;
    },

    editBranchClicked(branch) {
      this.editBranchData = branch;
      this.isRegistration = "Update Branch";
      this.branchFullName = branch.branch_name;

      this.branchRegOrUpdateDialog = true;
    },

    async changeAllow() {
      if (this.is_allowed_to_change) this.is_allowed_to_change = 1;
      else this.is_allowed_to_change = 0;
      this.allowCashierChangeLoading = true;
      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeAllowModifyCashier(is_allowed_to_change:${this.is_allowed_to_change},
                       partner_id: ${this.editPartnerData.partner_id}
                       ){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllowModifyCashier[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.allowCashierChangeLoading = false;
    },

    async confirmDeposit(depositItem) {
      const depositConfirm = confirm("Are you sure you want to confirm?");

      if (depositConfirm) {
        try {
          var confirmdepositResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                        confirmDeposit(
                          partner_id:${this.selectedDeposit.partner_id},
                          deposit_id:${depositItem.deposit_id},
                          amount:${depositItem.amount},
                        loan_amount: ${this.partnerCreditBalance},
                        oldAmount:${this.selectedDeposit.partner_balance}){
                     statusMessage
                    }

              }
              `,
            },
          });

          var result =
            confirmdepositResult.data.data.confirmDeposit[0].statusMessage;
          if (result.toString() == "true") {
            alert("Confirmed successfully!");

            this.depositDialog = false;
            this.getCashiers();
          } else if (result.toString() == "false") {
            alert(
              "Some thing went wrong please contact the owner of this system"
            );
          } else alert(result);
        } catch (err) {
          alert(err);
        }
      }
    },

    async rejectDeposit(depositItem) {
      const rejectConfirm = confirm(
        "Are you sure you want to reject this deposit?"
      );

      if (rejectConfirm) {
        try {
          var rejectdepositResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,
            data: {
              query: `mutation{
                       rejectDeposit(
                          deposit_id:${depositItem.deposit_id},
                         ){
                     statusMessage
                    }

              }
              `,
            },
          });

          var result =
            rejectdepositResult.data.data.rejectDeposit[0].statusMessage;
          if (result.toString() == "true") {
            alert("Rejected successfully!");
            this.depositDialog = false;
            this.getCashiers();
          } else if (result.toString() == "false") {
            alert(
              "Some thing went wrong please contact the owner of this system"
            );
          } else alert(result);
        } catch (err) {
          alert(err);
        }
      }
    },

    async changeMonitorAllow() {
      if (this.allow_monitor_anywhere) this.allow_monitor_anywhere = 1;
      else this.allow_monitor_anywhere = 0;
      this.allowMonitorLoading = true;
      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeAllowMonitor(allow_monitor_anywhere:${this.allow_monitor_anywhere},
                       partner_id: ${this.editPartnerData.partner_id}
                       
                       ){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllowMonitor[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.allowMonitorLoading = false;
    },

    async changeAllowLoggin(cashier) {
      var isAllowed = "";
      if (cashier.is_allowed_anywhere) isAllowed = 1;
      else isAllowed = 0;

      try {
        var changeAllowResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                       changeAllowLogin(
                        is_allowed_anywhere:${isAllowed},
                        cashier_id:${cashier.cashier_id}
                       ){
                       statusMessage
                    }
              
              }
              `,
          },
        });

        if (
          changeAllowResult.data.data.changeAllowLogin[0].statusMessage.toString() ==
          "true"
        ) {
        } else alert("Something went wrong");
      } catch (err) {
        alert(err);
      }
    },

    async registerCashier() {
      if (
        this.$refs.form.validate() &&
        this.choosedBranch != "" &&
        this.choosedPartner != ""
      ) {
        this.regOrUpdateLoading = true;
        try {
          var addCashierResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addCashier(
                 branch_id:${parseInt(this.choosedBranch.split(",")[1].trim())},
                  partner_id:${parseInt(
                    this.choosedPartner.split(",")[1].trim()
                  )},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address
                :"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
                 cashier_id
              }
              
              }
              `,
            },
          });

          if (
            addCashierResult.data.data.addCashier[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                addCashier(
                  branch_id:${parseInt(
                    this.choosedBranch.split(",")[1].trim()
                  )},
                  partner_id:${parseInt(
                    this.choosedPartner.split(",")[1].trim()
                  )},
                  cashier_id:${
                    addCashierResult.data.data.addCashier[0].cashier_id
                  },

                    cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address
                :"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
              },
            });

            this.getCashiers();
            this.cashierRegOrUpdateDialog = false;
          } else alert("Cashier already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updateCashier() {
      if (this.$refs.form.validate()) {
        this.regOrUpdateLoading = true;
        try {
          var updateCashierResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                updateCashier(
                  cashier_id:${this.cashierId},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address:"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            updateCashierResult.data.data.updateCashier[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                updateCashier(
                  cashier_id:${this.cashierId},cashier_full_name:"${this.fullName}",
                cashier_phone:"${this.phoneNumber}",cashier_address:"${this.address}",
                user_name:"${this.userName}",password:"${this.password}"){
                 statusMessage
              }
              
              }
              `,
              },
            });
            this.getCashiers();
            this.cashierRegOrUpdateDialog = false;
          } else alert("Cashier already exist or Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.regOrUpdateLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updatePartner() {
      if (this.$refs.partnerForm.validate()) {
        this.updatePartnerLoading = true;
        try {
          var updatePartnerResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                updatePartner(
                 partner_full_name:"${this.partnerFullName}",
                partner_phone:"${this.partnerPhoneNumber}",shop_number:"${this.shopNumber}",
                user_name:"${this.partnerUserName}",password:"${this.partnerPassword}",
                supper_partner_username: "${this.supper_partner_username}",
                supper_partner_password: "${this.supper_partner_password}",
                monitor_username: "${this.monitor_username}",
                monitor_password: "${this.monitor_password}",
                partner_id: ${this.editPartnerData.partner_id}
                
                ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            updatePartnerResult.data.data.updatePartner[0].statusMessage.toString() ==
            "true"
          ) {
            this.getCashiers();
            this.editPartnerDialog = false;
          } else alert("No change, Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.updatePartnerLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updateBranch() {
      if (this.$refs.branchForm.validate()) {
        this.updatePartnerLoading = true;
        try {
          var updateResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                updateBranch(
                 branch_name:"${this.branchFullName}",
             
                branch_id: ${this.editBranchData.branch_id}
                
                ){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            updateResult.data.data.updateBranch[0].statusMessage.toString() ==
            "true"
          ) {
            this.getCashiers();
            this.branchRegOrUpdateDialog = false;
          } else alert("No change, Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.updatePartnerLoading = false;
      } else alert("Please fill all the required fields");
    },

    async addPartnerData() {
      if (this.$refs.partnerForm.validate()) {
        this.updatePartnerLoading = true;
        try {
          var updatePartnerResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addPartner(
                 partner_full_name:"${this.partnerFullName}",
                partner_phone:"${this.partnerPhoneNumber}",shop_number:"${this.shopNumber}",
                user_name:"${this.partnerUserName}",password:"${this.partnerPassword}",
                supper_partner_username: "${this.supper_partner_username}",
                supper_partner_password: "${this.supper_partner_password}",
                monitor_username: "${this.monitor_username}",
                monitor_password: "${this.monitor_password}",
                
                ){
                 statusMessage
                 partner_id
              }
              
              }
              `,
            },
          });

          if (
            updatePartnerResult.data.data.addPartner[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                addPartner(
                  partner_id: ${updatePartnerResult.data.data.addPartner[0].partner_id},
                 partner_full_name:"${this.partnerFullName}",
                partner_phone:"${this.partnerPhoneNumber}",shop_number:"${this.shopNumber}",
                user_name:"${this.partnerUserName}",password:"${this.partnerPassword}",
             
                
                ){
                 statusMessage
                
              }
              
              }
              `,
              },
            });

            this.editPartnerDialog = false;
            this.getCashiers();
          } else alert("No change, Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.updatePartnerLoading = false;
      } else alert("Please fill all the required fields");
    },

    async addBranchData() {
      if (this.$refs.branchForm.validate() && this.choosedPartner != "") {
        this.updatePartnerLoading = true;
        try {
          var branchResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                addBranch(
                 branch_name:"${this.branchFullName}",
                partner_id:${parseInt(this.choosedPartner.split(",")[1].trim())}
               
                
                ){
                 statusMessage
                 branch_id
                 
              }
              
              }
              `,
            },
          });

          if (
            branchResult.data.data.addBranch[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                addBranch(
                 branch_name:"${this.branchFullName}",
                partner_id:${parseInt(
                  this.choosedPartner.split(",")[1].trim()
                )},
                branch_id:${branchResult.data.data.addBranch[0].branch_id}
               
                
                ){
                 statusMessage
                 branch_id
                 
              }
              
              }
              `,
              },
            });

            this.branchRegOrUpdateDialog = false;
            this.getCashiers();
          } else alert("No change, Something went wrong");
        } catch (err) {
          alert(err);
        }
        this.updatePartnerLoading = false;
      } else alert("Please fill all the required fields");
    },

    async updateBlockingDate() {
      this.updateBlockingDateLoading = true;
      try {
        var updateBlockingDateResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                changeBlockingDate(
                  day_block:${this.day_block},
                  partner_id: ${this.editPartnerData.partner_id}
                
                ){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          updateBlockingDateResult.data.data.changeBlockingDate[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
          this.editPartnerDialog = false;
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }
      this.updateBlockingDateLoading = false;
    },
    async blockOrActivateCashier(cashier) {
      this.activateLoading = true;
      try {
        var blockAcrivateCashierResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                blockOrActivateCashier(
                  cashier_id:${cashier.cashier_id}, is_active:${
              cashier.is_active == 1 ? 0 : 1
            }){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          blockAcrivateCashierResult.data.data.blockOrActivateCashier[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.activateLoading = false;
    },

    async blockOrActivatePartner(partner) {
      this.partnerActivateLoading = true;
      try {
        var blockAcrivatePartnerResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
                blockOrActivatePartner(
                  partner_id:${partner.partner_id}, is_active:${
              partner.is_active == 1 ? 0 : 1
            }){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          blockAcrivatePartnerResult.data.data.blockOrActivatePartner[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }

      this.partnerActivateLoading = false;
    },

    async deleteCashier(cashier) {
      const deleteConfirm = confirm("Are you sure you want to delete ?");
      if (deleteConfirm) {
        try {
          var deleteCashierResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                deleteCashier(
                  cashier_id:${cashier}){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            deleteCashierResult.data.data.deleteCashier[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                deleteCashier(
                  cashier_id:${cashier}){
                 statusMessage
              }
              
              }
              `,
              },
            });
            this.getCashiers();
          } else alert("not deleted, Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async deletePartner(id) {
      const deleteConfirm = confirm("Are you sure you want to delete ?");
      if (deleteConfirm) {
        try {
          var deleteCashierResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                deletePartner(
                  partner_id:${id}){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            deleteCashierResult.data.data.deletePartner[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                  deletePartner(
                    partner_id:${id}){
                 statusMessage
              }
              
              }
              `,
              },
            });
            this.getCashiers();
          } else alert("not deleted, Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    async deleteBranch(id) {
      const deleteConfirm = confirm("Are you sure you want to delete ?");
      if (deleteConfirm) {
        try {
          var deleteResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                deleteBranch(
                  branch_id:${id}){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            deleteResult.data.data.deleteBranch[0].statusMessage.toString() ==
            "true"
          ) {
            await axios({
              method: "POST",
              url: this.$store.state.spinAdminURL,

              data: {
                query: `mutation{
                  deleteBranch(
                  branch_id:${id}){
                 statusMessage
              }
              
              }
              `,
              },
            });
            this.getCashiers();
          } else alert("not deleted, Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },
    async getCashiers() {
      this.pageLoading = true;
      try {
        var cashierResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getCashiers{
                cashier_id
                partner_id 
                cashier_full_name
                cashier_phone
                cashier_address
                user_name
                password
                is_active
                is_loggedin
                is_allowed_anywhere
                getPartner{
                  partner_full_name
                }
                getBranch{
                  branch_name
                }
    
                }
              }
              `,
          },
        });
        this.cashiers = cashierResult.data.data.getCashiers;
      } catch (err) {
        alert(err);
      }

      try {
        var partnerResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getPartner {
                 partner_id
                 partner_full_name
                 partner_phone
                 shop_number
                 partner_balance
                 user_name
                 password
                 is_active

                 supper_partner_username
                 supper_partner_password
                 monitor_username
                 monitor_password
                 day_block_date
                 day_block
                 is_allowed_to_change
                 allow_monitor_anywhere
                 ticket_layout
                 logo

                 getCreditBalance{
                  credit_id
                  partner_id
                  credit_balance
                }

                 getDeposit{
                 deposit_id
                  partner_id                  
                  amount
                  transaction_id
                  deposit_date
                  is_confirmed
                  
                }
               }
              }
              `,
          },
        });
        this.partner = partnerResult.data.data.getPartner;
        // this.partnerFullName = this.partner[0].partner_full_name;
        // this.partnerPhoneNumber = this.partner[0].partner_phone;
        // this.shopNumber = this.partner[0].shop_number;
        // this.partnerUserName = this.partner[0].user_name;
        // this.partnerPassword = this.partner[0].password;

        // this.supper_partner_username = this.partner[0].supper_partner_username;
        // this.supper_partner_password = this.partner[0].supper_partner_password;
        // this.monitor_username = this.partner[0].monitor_username;
        // this.monitor_password = this.partner[0].monitor_password;
        // this.is_allowed_to_change = this.partner[0].is_allowed_to_change;
        // this.allow_monitor_anywhere = this.partner[0].allow_monitor_anywhere;

        // this.ticket_layout = this.partner[0].ticket_layout;
        // this.logo = this.partner[0].logo;

        // var sec = 24 * 60 * 60 * 1000;

        // this.day_block =
        //   "Given days = " +
        //   this.partner[0].day_block +
        //   "  ;  Remain days = " +
        //   Math.round(
        //     this.partner[0].day_block -
        //       (Date.now() -
        //         Number(
        //           this.partner[0].day_block_date != ""
        //             ? this.partner[0].day_block_date
        //             : 0
        //         )) /
        //         sec
        //   );

        // this.day_block_date = this.partner[0].day_block_date;
      } catch (err) {}

      try {
        var branchResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
              getBranch{
                branch_id
                partner_id
                branch_name
                is_active
                getPartner{
                  partner_full_name
                }
    
    
    
              }
              }
              `,
          },
        });
        this.branchs = branchResult.data.data.getBranch;
      } catch (err) {}

      this.pageLoading = false;
    },

    async logout(cashier) {
      try {
        await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
               loggedInCashier(
                cashier_id:${cashier.cashier_id},
                is_loggedin:0
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        await this.getCashiers();
      } catch (err) {
        alert(err);
      }
    },
    async clearCoordinate(cashier) {
      try {
        await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
              clearCoordinate(
                cashier_id:${cashier.cashier_id}
              
             )
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        await this.getCashiers();
      } catch (err) {
        alert(err);
      }
    },

    async clearMonitorCoordinate() {
      try {
        await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
              clearMonitorCoordinate
                {
                      statusMessage

                   }
              }
              `,
          },
        });
        await this.getCashiers();
      } catch (err) {
        alert(err);
      }
    },
    async changeTicketLayout() {
      try {
        var editLayoutResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
              changeTicketLayout(
                ticket_layout:"${this.ticket_layout}",
                partner_id: ${this.editPartnerData.partner_id}
                ){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          editLayoutResult.data.data.changeTicketLayout[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }
    },

    async changeLogo() {
      try {
        var editLogoResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `mutation{
              changeLogo(
                logo:"${this.logo}",  partner_id: ${this.editPartnerData.partner_id}){
                 statusMessage
              }
              
              }
              `,
          },
        });
        if (
          editLogoResult.data.data.changeLogo[0].statusMessage.toString() ==
          "true"
        ) {
          this.getCashiers();
        } else alert("No change, Something went wrong");
      } catch (err) {
        alert(err);
      }
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getCashiers();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
