import { render, staticRenderFns } from "./gameStat.vue?vue&type=template&id=062da6b4&scoped=true&"
import script from "./gameStat.vue?vue&type=script&lang=js&"
export * from "./gameStat.vue?vue&type=script&lang=js&"
import style0 from "./gameStat.vue?vue&type=style&index=0&id=062da6b4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062da6b4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBtn,VCard,VCardText,VContainer,VFlex,VLayout,VProgressCircular,VProgressLinear})
